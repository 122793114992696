.decidimGeo__container .js-decidimgeo.decidimgeo__map {
  .leaflet-control-zoom {
    margin-bottom: 32px;
    flex-direction: column !important;
    margin-right: 16px;
    gap: 0;
    display: flex;
    justify-content: space-around;
    border: 1px solid #d9d9d9 !important;
    border-radius: 4px;
    box-shadow: 0px 2px 2px 0px #0000001f;
    border-color: #d9d9d9 !important;
  }

  .leaflet-bar a.leaflet-control-zoom-in {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-color: transparent !important;
    border-bottom-width: 0 !important;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -1px;
      right: -1px;
      height: 1px;
      background: #d9d9d9;
    }
  }
  .leaflet-bar a.leaflet-control-zoom-out {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-top-color: transparent !important;
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
  }
  .leaflet-bar a.leaflet-control-zoom-in,
  .leaflet-bar a.leaflet-control-zoom-out {
    font-size: 26px;
    font-weight: 700;
    line-height: 40px;
    width: 40px;
    height: 40px;
    color: #808080 !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    border-color: transparent !important;
    &:hover {
      color: #808080;
    }
  }
}
