@import "./decidim_geo_mixins";
@import "./decidim_geo_globals";
@import "./decidim_geo_zoom_controls";
@import "./decidim_geo_scope_dropdown";
@import "./decidim_geo_filter_dropdown";
@import "./decidim_geo_drawer_list";

@import "./decidim_geo_mobile";

.decidimGeo__container {
  position: relative;
  .js-decidimgeo.decidimgeo__map {
    display: flex;
    min-height: 640px;
    height: 80vh;
    width: 100%;
    max-width: 100%;
    max-height: 100vh;
    * {
      box-sizing: border-box;
    }
  }
}
